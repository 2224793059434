import { useEffect, useState } from "react"

const getScreenSize = () => {
  if (typeof window === "undefined") return "xs"
  const { innerWidth = 0 } = window || {}
  if (innerWidth > 1200) return "xl"
  if (innerWidth > 992) return "lg"
  if (innerWidth > 768) return "md"
  if (innerWidth > 576) return "sm"
  return "xs"
}

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState(undefined)

  useEffect(() => {
    function handleResize() {
      setScreenSize(getScreenSize())
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { screenSize }
}
