import styled from "styled-components"

export const Section = styled.div`
  padding-top: ${({ top }) => top || 25}px;
  padding-bottom: ${({ bottom }) => bottom || 25}px;
`

export const WhiteSection = styled(Section)`
  background-color: white;
`

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  ${({ top }) => (top ? `padding-top: ${top}px` : null)};
  ${({ bottom }) => (bottom ? `padding-bottom: ${bottom}px` : null)};
  @media ${({ theme }) => theme.breakpoints.md} {
    padding-right: 50px;
    padding-left: 50px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    max-width: 894px;
    padding-right: 25px;
    padding-left: 25px;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: 1230px;
  }
`

export const FullRow = styled.div`
  width: 100vw;
  margin-left: -25px;
  @media ${({ theme }) => theme.breakpoints.md} {
    margin-left: -50px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    margin-left: -25px;
    margin-left: calc(-100vw / 2 + 894px / 2 - 25px);
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    margin-left: calc(-100vw / 2 + 1230px / 2 - 25px);
  }
`

export const SectionContainer = styled(Container)`
  padding-top: ${({ top }) => top || 25}px;
  padding-bottom: ${({ bottom }) => bottom || 25}px;
  @media ${({ theme }) => theme.breakpoints.md} {
    padding-bottom: ${({ bottom }) => bottom || 50}px;
  }
`

export const FullContainer = styled(Container)`
  @media (max-width: 1439px) {
    max-width: none;
    padding: 0;
  }
`

export const SmallContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  ${({ top }) => (top ? `padding-top: ${top}px` : null)};
  ${({ bottom }) => (bottom ? `padding-bottom: ${bottom}px` : null)};
  max-width: 425px;
  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 425px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 425px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    max-width: 650px;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: 650px;
  }
`

export const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px;
`

export const TitleWrapper = styled.div`
  margin-bottom: 25px;
`
