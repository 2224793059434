import { useContext } from "react"
import dynamic from "next/dynamic"
import { ModalContext } from "../ModalContext"

const CovidModal = dynamic(() => import("../CovidModal"))

export default function GlobalModals() {
  const { show, modal } = useContext(ModalContext)

  if (!show) return null

  if (modal === "covidModal") return <CovidModal />

  return null
}
