import styled from "styled-components"
import Icon from "@/components/icons/icon"
import { Calendar } from "@/components/icons/calendar"
import { Phone } from "@/components/icons/phone"

const PhoneLink = styled.a`
  white-space: nowrap;
  display: inline-block;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.deepPink};
  border-radius: 24px;
  font-size: 24px;
  line-height: 32px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.deepPink};
  }
  @media (min-width: 400px) {
    font-size: 32px;
  }
`

export function CallUsButton(props) {
  const { phoneNumber, ...rest } = props
  return (
    <PhoneLink href={`tel:+34${phoneNumber.replace(/\s+/g, "")}`} {...rest} arial-label="Botón para llamar y reservar">
      <Icon Icon={Phone} size="md" className="mr-3" />
      {phoneNumber}
    </PhoneLink>
  )
}

CallUsButton.defaultProps = {
  phoneNumber: "660 67 18 30"
}

const SmallPhone = styled(PhoneLink)`
  color: ${({ theme, $inputColor }) => theme.colors[$inputColor]};
  border-width: 2px;
  background: none;
`

export function SmallCallUsButton(props) {
  const { phoneNumber, ...rest } = props
  return (
    <SmallPhone href={`tel:+34${phoneNumber.replace(/\s+/g, "")}`} {...rest} arial-label="Botón para llamar y reservar">
      <Icon Icon={Phone} size="md" className="mr-3" />
    </SmallPhone>
  )
}

SmallCallUsButton.defaultProps = {
  $inputColor: "white",
  phoneNumber: "660 67 18 30"
}

const Doctoralia = styled.a`
  display: inline-block;
  background: ${({ theme, $white }) => theme.colors[$white ? "white" : "deepPink"]};
  color: ${({ theme, $white }) => theme.colors[$white ? "deepPink" : "white"]};
  border-radius: 24px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  border: none;
  padding: 9px 15px;
  white-space: nowrap;
  svg {
    font-size: 28px;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme, $white }) => theme.colors[$white ? "deepPink" : "white"]};
  }
`

export function DoctoraliaCta(props) {
  return (
    <Doctoralia
      href="https://www.doctoralia.es/clinicas/physio-wow?saasonly=true"
      target="_blank"
      rel="nofollow noopener noreferrer"
      {...props}>
      <Icon Icon={Calendar} size="md" className="mr-3" />
      Reserva online
    </Doctoralia>
  )
}

export function WhatsappCta({ shareText = "Hola, quiero reservar cita en Physio WOW", ...rest }) {
  return (
    <Doctoralia
      href={`https://wa.me/34669139695?text=${encodeURI(shareText)}`}
      target="_blank"
      rel="nofollow noopener noreferrer"
      {...rest}>
      <Icon Icon={Calendar} size="md" className="mr-3" />
      Reserva online
    </Doctoralia>
  )
}

const Button = styled.button`
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 28px;
  border: none;
  ${({ size = "lg" }) => sizeStyle[size]}
  vertical-align: middle;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 100%;
  &::first-letter {
    text-transform: capitalize;
  }
`

const sizeStyle = {
  xs: `
    width: 90px;
    font-size: 12px;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    svg {
      font-size: 12px;
    }
  `,
  sm: `
    width: 150px;
    font-size: 16px;
    line-height: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
    svg {
      font-size: 16px;
    }
  `,
  md: `
    width: 225px;
    font-size: 16px;
    line-height: 28px;
    padding-top: 10px;
    padding-bottom: 10px;
    svg {
      font-size: 28px;
    }
  `,
  lg: `
    width: 325px;
    font-size: 21px;
    line-height: 32px;
    padding: 13px 20px;
    svg {
      font-size: 32px;
    }
  `
}

export const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.colors.deepPink};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ $shadow }) => ($shadow ? "0 4px 16px 0 rgba(255, 69, 151, 0.6)" : "none")};
  border: 1px solid ${({ theme }) => theme.colors.deepPink};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.mediumVioletRed};
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.mediumVioletRed};
  }
`

export const SecondaryButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.deepPink};
  color: ${({ theme }) => theme.colors.deepPink};
  &:hover {
    background: ${({ theme }) => theme.colors.mediumVioletRed};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.mediumVioletRed};
  }
`

export const WhiteButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.deepPink};
  box-shadow: 0 2px 4px 0 rgba(172, 181, 198, 0.8);
  &:hover {
    box-shadow: none;
  }
`

export const DoubleButtonsWrapper = styled.div`
  max-width: 100%;
  display: inline-flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: row;
  }
  button:first-child {
    margin-bottom: 25px;
    @media ${({ theme }) => theme.breakpoints.md} {
      margin-bottom: 0;
      margin-right: 18px;
    }
    @media ${({ theme }) => theme.breakpoints.lg} {
      margin-right: 50px;
    }
  }
`
