import * as PropTypes from "prop-types"
import LazyLoad from "react-lazyload"
import styled from "styled-components"
import dynamic from "next/dynamic"
import ModalContextProvider from "@/components/Modals/ModalContext"
import GlobalModals from "@/components/Modals/GlobalModals"
import TopBar from "../TopBar"

const Footer = dynamic(() => import("../Footer"))
const Cookies = dynamic(() => import("../Cookies"), { ssr: false })
const FloatingCta = dynamic(() => import("../FloatingCta"), { ssr: false })

const Content = styled.div`
  ${({ $padding }) => ($padding ? "padding-top: 80px;" : null)}
`

export default function DefaultLayout(props) {
  const { children, padding, topBarProps, page, ctaProps, phoneNumber } = props

  return (
    <ModalContextProvider>
      <TopBar pageName={page} phoneNumber={phoneNumber} {...topBarProps} />
      <Content $padding={padding}>{children}</Content>
      <GlobalModals />
      <Cookies />
      <FloatingCta page={page} phoneNumber={phoneNumber} {...ctaProps} />
      <LazyLoad height={500} once offset={100}>
        <Footer />
      </LazyLoad>
    </ModalContextProvider>
  )
}

DefaultLayout.defaultProps = {
  breadcrumbs: null,
  padding: false
}

DefaultLayout.propTypes = {
  padding: PropTypes.bool,
  page: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object)
}
