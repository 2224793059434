import * as PropTypes from "prop-types"
import styled from "styled-components"

const iconSize = {
  xs: 14,
  sm: 18,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 96
}

const Svg = styled.svg`
  vertical-align: -0.125em;
  font-size: ${({ $iSize }) => $iSize}px;
  ${({ $iconColor, theme }) => ($iconColor ? `color: ${theme.colors[$iconColor]};` : "")}
`

export default function Icon(props) {
  const { color, size, Icon: ScIcon, ...rest } = props

  return (
    <Svg
      {...rest}
      $iconColor={color}
      $iSize={iconSize[size]}
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 48 48"
    >
      <ScIcon />
    </Svg>
  )
}

Icon.defaultProps = {
  color: undefined,
  size: "md"
}

Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  Icon: PropTypes.func.isRequired
}
