import styled from "styled-components"
import Link from "next/link"
import Typography from "@/styled/Typography"
import Icon from "@/components/icons/icon"
import { Letter } from "@/components/icons/letter"
import { Whatsapp } from "@/components/icons/whatsapp"
import { Instagram } from "@/components/icons/instagram"
import { Facebook } from "@/components/icons/facebook"
import { Close } from "@/components/icons/close"

const Background = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.darkBlue};
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  min-width: 250px;
  z-index: 999;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  transition: left 0.5s;
  &.show {
    left: 0;
  }
`

const CloseSection = styled.div`
  text-align: right;
`
const MenuSection = styled.div``
const SocialSection = styled.div``

const SocialIcon = styled(Icon)`
  margin-right: 20px;
`

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 10px;
  }
`

const MenuItem = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 18px;
`

const Divider = styled.hr`
  height: 1px;
  border: 0;
  margin: 1em 0;
  border: 1px solid ${({ theme }) => theme.colors.silver}80;
`

export default function SideMenu(props) {
  const { open, onClose } = props

  return (
    <Background className={open ? "show" : "hide"}>
      <CloseSection>
        <Icon color="white" onClick={onClose} Icon={Close} />
      </CloseSection>
      <MenuSection>
        <Typography el="h4" variant="title2" $txtColor="white">
          Secciones
        </Typography>
        <Menu>
          <li>
            <MenuItem href="/" passHref>
              Physio WOW
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/nuestros-fisios" passHref>
              Nuestros fisios
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/blog" passHref>
              Blog
            </MenuItem>
          </li>
        </Menu>
        <Divider />
        <Menu>
          <li>
            <MenuItem href="/fisioterapia-barcelona" passHref>
              Fisioterapia Barcelona
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/fisioterapia-sant-cugat" passHref>
              Fisioterapia Sant Cugat
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/fisioterapia-sant-joan-despi" passHref>
              Fisioterapia Sant Joan Despí
            </MenuItem>
          </li>
        </Menu>
        <Divider />
        <Menu>
          <li>
            <MenuItem href="/tratamientos/fisioterapia" passHref>
              Fisioterapia
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/tratamientos/diatermia" passHref>
              Diatermia
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/tratamientos/electropuncion" passHref>
              Electropunción
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/tratamientos/puncion-seca" passHref>
              Punción seca
            </MenuItem>
          </li>
        </Menu>
        <Divider />
        <Menu>
          <li>
            <MenuItem href="/alquiler-magnetoterapia" passHref>
              Alquiler magnetoterapia
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/alquiler-magnetoterapia/sant_cugat_del_valles" passHref>
              Magnetoterapia Sant Cugat
            </MenuItem>
          </li>
          <li>
            <MenuItem href="/alquiler-magnetoterapia/barcelona" passHref>
              Magnetoterapia Barcelona
            </MenuItem>
          </li>
        </Menu>
      </MenuSection>
      <SocialSection>
        <a href="https://facebook.com/physiowow" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <SocialIcon color="skyBlue" Icon={Facebook} />
        </a>
        <a href="https://instagram.com/physiowow" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <SocialIcon color="skyBlue" Icon={Instagram} />
        </a>
        <a
          href={`https://wa.me/34669139695?text=${encodeURI("Hola, quiero reservar cita en Physio WOW")}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Whatsapp">
          <SocialIcon color="skyBlue" Icon={Whatsapp} />
        </a>
        <a href="mailto:physiowow@gmail.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
          <SocialIcon color="skyBlue" Icon={Letter} />
        </a>
      </SocialSection>
    </Background>
  )
}
