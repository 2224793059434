import { createContext, useState } from "react"
import * as PropTypes from "prop-types"

export const ModalContext = createContext({
  hideModal: () => null,
  show: false,
  modal: null,
  data: {},
  showModal: () => null
})

const ModalContextProvider = props => {
  const { children } = props

  const [show, setShow] = useState(false)
  const [modal, setModal] = useState(null)
  const [data, setData] = useState({})

  const hideModal = () => {
    setModal(null)
    setShow(false)
    setData({})
  }

  const showModal = (m, d) => {
    setModal(m)
    setShow(true)
    setData(d)
  }

  return <ModalContext.Provider value={{ show, modal, hideModal, showModal, data }}>{children}</ModalContext.Provider>
}

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalContextProvider
